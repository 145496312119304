<template>
    <footer class="bg-blacks-90 relative">
        <ButtonBackToTop></ButtonBackToTop>
        <div class="bg-[#202020]">
            <div class="container py-10">
                <div class="flex flex-col lg:flex-row gap-5 lg:gap-12">
                    <div class="w-full lg:max-w-6/12">
                        <v-content option_key="description_signUp_footer.global" class="text-white" />
                    </div>
                    <div class="w-full">
                        <form
                            @submit.prevent="sendEmail"
                            class="w-full flex flex-col md:flex-row md:items-center gap-6"
                        >
                            <div class="relative bg-white p-4 text-blacks-30 text-lg flex items-center w-full gap-2.5">
                                <i class="i-ic:outline-mail"></i>
                                <input
                                    type="email"
                                    name="email"
                                    v-model="email"
                                    required
                                    class="w-full"
                                    placeholder="Nhập Email"
                                />
                            </div>
                            <button
                                class="btn-effect-dark flex-none py-5 px-12 bg-blacks-100 text-lg font-medium text-white w-full md:w-max"
                            >
                                <p v-if="!loading">Đăng ký</p>
                                <GlobalLoadingSearch v-else class="mx-auto" />
                            </button>
                        </form>
                        <div
                            v-if="sendSuccess"
                            class="flex items-center gap-2 text-sm text-white mt-2 text-lg font-medium"
                        >
                            <i class="i-ic:baseline-check-circle bg-[#01AB56]"></i>
                            <p class="text-green-600">Gửi email thành công</p>
                        </div>
                        <div class="text-[#F8F8F8] text-sm mt-4">
                            <v-content option_key="footer_text_privacy.global"></v-content>
                            <!-- <v-link option_key="footer_link_privacy.global" class="inline-block">
                                <v-text
                                    :icons="[]"
                                    option_key="footer_text_link_privacy.global"
                                    class="underline"
                                ></v-text>
                            </v-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-blacks-90">
            <div class="container">
                <footer class="block text-white">
                    <div class="pb-6 pt-16 lg:pt-15 xl:pt-25 mx-auto w-full">
                        <div class="mb-6 md:hidden">
                            <a href="#" class="inline-block w-37 h-11">
                                <img src="/images/global/logo-footer.png" alt="" class="object-contain h-full w-full" />
                            </a>
                        </div>
                        <div
                            v-if="dataRender && dataRender.length > 0"
                            class="grid grid-cols-2 justify-between gap-4 max-[991px]:grid-flow-col max-[991px]:[grid-template:'.'_auto_'.'_auto_/_0.75fr_0.75fr_0.75fr] max-[767px]:gap-y-8 max-[479px]:auto-cols-auto max-[479px]:grid-flow-dense sm:grid-cols-2 sm:gap-4 md:grid-cols-[max-content_auto_auto_auto_0.75fr] lg:gap-15 xl:gap-20 pb-5 xl:pb-12"
                        >
                            <div class="hidden md:flex">
                                <div class="">
                                    <a href="#" class="inline-block w-37 h-11">
                                        <img
                                            src="/images/global/logo-footer.png"
                                            alt=""
                                            class="object-contain h-full w-full"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div v-for="item in dataRender" class="flex flex-col items-start">
                                <div class="mb-6 ml-0 mr-0 max-[991px]: lg:ml-0 lg:mr-0">
                                    <p class="font-medium text-lg sm:text-xl">{{ item?.name }}</p>
                                </div>
                                <nuxt-link
                                    v-if="item?.childs && item?.childs.length > 0"
                                    v-for="child in item?.childs"
                                    :to="child?.url"
                                    class="pb-4 text-sm text-blacks-10 transition hover:text-white"
                                    >{{ child?.name }}</nuxt-link
                                >
                            </div>

                            <div>
                                <div class="flex flex-col items-start">
                                    <div class="mb-6 ml-0 mr-0 max-[991px]: lg:ml-0 lg:mr-0">
                                        <v-text
                                            option_key="heading_tcare_footer.global"
                                            class="font-medium text-lg sm:text-xl"
                                        ></v-text>
                                    </div>
                                    <a :href="'mailto:' + emailTcare" class="pb-4">
                                        <v-text
                                            href="#"
                                            option_key="email_tcare_footer.global"
                                            class="text-sm text-blacks-10 transition hover:text-white"
                                        ></v-text>
                                    </a>
                                    <a :href="'tel:' + phoneTcare" class="pb-8">
                                        <v-text
                                            option_key="phone_tcare_footer.global"
                                            class="text-sm text-blacks-10 transition hover:text-white"
                                        ></v-text>
                                    </a>
                                </div>
                                <div class="flex flex-col items-start">
                                    <div class="mb-6 ml-0 mr-0 max-[991px]: lg:ml-0 lg:mr-0">
                                        <v-text
                                            option_key="heading_agency_footer.global"
                                            class="font-medium text-lg sm:text-xl"
                                        ></v-text>
                                    </div>
                                    <a :href="'mailto:' + emailAgency" class="pb-4">
                                        <v-text
                                            option_key="email_agency_footer.global"
                                            class="text-sm text-blacks-10 transition hover:text-white"
                                        ></v-text>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-between b-t-1 b-t-gray-200/20 py-6">
                            <div class="text-sm text-[#D0D5DD] flex items-center gap-1">
                                <p>© {{ currentYear }}</p>
                                <v-text option_key="text_copyright_footer.global"></v-text>
                            </div>
                            <div class="flex items-center gap-9">
                                <v-link option_key="facebook_footer.global">
                                    <div class="w-6 h-6 group block">
                                        <IconsFacebook
                                            class="text-white hover:text-gray-200 hover:scale-110 w-full h-full"
                                        />
                                    </div>
                                </v-link>
                                <v-link option_key="insta_footer.global">
                                    <div class="w-6 h-6 group block">
                                        <IconsInstagram
                                            class="text-white hover:text-gray-200 hover:scale-110 w-full h-full"
                                        />
                                    </div>
                                </v-link>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { useReCaptcha } from 'vue-recaptcha-v3'
const { getDataFooterMenu } = useAppGlobalData()
const data = ref()
const dataRender = computed(() => {
    return data.value
})
const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const email = ref()
const sendSuccess = ref(false)
const loading = ref(false)
const phoneTcare = ref(getDataGlobalByKey('vi-VN', `TEXT__phone_tcare_footer.global`)?.option_value)
const emailTcare = ref(getDataGlobalByKey('vi-VN', `TEXT__email_tcare_footer.global`)?.option_value)
const emailAgency = ref(getDataGlobalByKey('vi-VN', `TEXT__email_agency_footer.global`)?.option_value)

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded()

    // get the token, a custom action could be added as argument to the method
    const token = await recaptchaInstance?.executeRecaptcha('login')

    return token
}
const sendEmail = async (): Promise<void> => {
    if (email.value) {
        loading.value = true

        const token = await recaptcha()

        fetch(`/api/subscription?response=${token}`, {
            method: 'POST',
            body: JSON.stringify({ email: email.value })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.code == 201) {
                    loading.value = false
                    sendSuccess.value = true
                    setTimeout(() => {
                        sendSuccess.value = false
                    }, 5000)
                    email.value = ''
                }
            })
            .catch((error) => {
                sendSuccess.value = false
            })
    }
}
onMounted(async () => {
    data.value = await getDataFooterMenu('vi-VN')
})
</script>
<style></style>
